/*
 * Shortcode: team.less
 * -----------------------------------------------
*/

.team-members {
  overflow: hidden;
  position: relative;
}
.team-members .team-details {
  bottom: -50px;
  position: absolute;
  transition: all 300ms ease-in-out 0s;
  width: 100%;
}
.team-members:hover .team-details {
  bottom: 0;
}
