/*
 * Shortcode: lightbox.less
 * -----------------------------------------------
*/
.nivo-lightbox-theme-default .nivo-lightbox-nav {
  height: 100%;
  top: 0;
}
.lightbox {
	.lb-dataContainer{
	left: 0;
	position: absolute;
	right: 0;
	top: -45px;
	}
}