/*
 * Shortcode: pegination.less
 * -----------------------------------------------
*/
.pagination {
	&.theme-colored {
		li.active a {
		  background: #BF9E58;
		  color: #fff;
		  border: 1px solid transparent;
		}

	}

	&.dark {
		li a {
			color: #333;
		}
		li.active a {
		  background: #333;
		  color: #fff;
		  border: 1px solid transparent;
		}
	}
}

.pager {
	&.theme-colored {
		li.active a {
		  color: #fff;
		  border: 1px solid transparent;
		}
	}

	&.dark {
		li {
			a {
			  color: #fff;
			  background-color: #333;
			  border: 1px solid transparent;
			}
		}
	}
}