/*
 * practice-area.less
 * -----------------------------------------------
*/
.practice-area {
	overflow: hidden;
	position: relative;
	&:hover {
		.practice-name {
			opacity: 1;
			right: 0;
			.transition(all .2s ease-in-out);
		}
	}
}