/*
 * Shortcode: Piechart.less
 * -----------------------------------------------
*/
.single-cause-piechart {
  position: relative;
}
.single-cause-piechart .piechart-block {
  border-radius: 50%;
}
.single-cause-piechart .piechart canvas {
	width: 190px;
	height: 190px;
}
.single-cause-piechart .piechart-block .piechart {
  min-height: 190px;
  min-width: 190px;
}
.single-cause-piechart .piechart-block .percent::after {
  font-size: 28px;
}
.single-cause-piechart .piechart-block .percent {
  font-family: open sans;
  font-size: 36px;
  left: 10px;
  position: absolute;
  right: 0;
  top: 34%;
}