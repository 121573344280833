/*
 * Shortcode: progress-bar.less
 * -----------------------------------------------
*/
.progress-item {
	margin-top: 20px;
	.progress {
		background-color: #e7e8ea;
		box-shadow: none;
		border-radius: 0;
		height: 14px;
		margin-bottom: 15px;
		overflow: visible;
	}

	.progress-bar {
	    box-shadow: none;
		font-size: 11px;
		padding-left: 8px;
		position: relative;
		text-align: left;
		width: 0;
		line-height: 14px;
		.transition(all 1s ease 0s);
		.percent {
			background-color: none;
    		 color: #333;
            font-size: 12px;
            font-weight: 500;
            padding: 0 5px;
            position: absolute;
            right: 5px;
            top: -20px;
		}
	}

	&.progress-white {
		.progress {
			padding: 2px;
		}
	}

	&.style2 {
		.progress {
			height: 25px;
		}

		.progress-bar {
			.percent {
				background: none;
				color: @black-555;
				font-size: 14px;
			}
		}
	}
}




