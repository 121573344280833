/*  
 * home-boxes.less 
 * -----------------------------------------------
*/
.home-boxes {
	.each-box {
	overflow-x: hidden;
	}
  
	.bg-icon {
	  bottom: 0;
	  color: #fff;
	  font-size: 58px;
	  line-height: 1;
	  opacity: 0.3;
	  position: absolute;
	  right: 30px;
	  transform: rotate(-40deg);
	}
}